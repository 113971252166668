div div.degree {
    color: rgb(235, 235, 235);
    letter-spacing: 0.3px;
    font-weight: 600;
    font-family: 'Poppins';
}

div div.institution {
    color: rgb(235, 235, 235);
    font-weight: 200;
    letter-spacing: 0.5px;
    font-family: 'Poppins';
    text-transform: uppercase;
}

div span.year {
    color: rgb(235, 235, 235);
}

div span.dash {
    color: rgb(235, 235, 235);
}