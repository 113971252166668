.popup {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;

}

.popup-inner {
    width: 100%;
    height: 100%;
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
    background: #141414;
    color: #fff;
    position: relative;
    z-index: 999;
    border-radius: 10px;

}

.scrollClass {
    max-width: 700px;
    max-height: 500px;
    background: #141414;
    overflow-x: hidden;
    overflow-y: auto;
}

.ModalContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 1.8;
    color: #fff;

}

div.heading {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    display: flex;
    justify-content: space-between;
    background: rgba(249, 0, 77, 0.8);
    margin-bottom: 0.5rem;

}

div.heading .p-heading {
    font-size: 1.4rem;
    font-weight: 500;
    width: 300px;
    display: block;
    padding-left: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 0.5rem;
}

div.heading-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0.5rem;
    margin-bottom: 0.7rem;
    padding-bottom: 0.5rem;
    border-bottom: solid 5px rgb(249, 0, 77);
}

.ModalContent h2 {
    font-size: 1.2rem;
    font-weight: 500;
}

.ModalContent h2.month {
    margin-top: 0;
    font-size: 1rem;
    font-weight: 200;
}

.ModalContent p {
    margin-bottom: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;
}

.ModalContent p.aim {
    font-weight: 500;
    padding-bottom: 0.5rem;
    border-bottom: solid 1px rgb(249, 0, 77);
}

.ModalContent p.elements-used {
    align-self: flex-start;
}

.ModalContent p.elements-used span.element-title {
    border-bottom: solid 1px rgb(249, 0, 77);
    padding-bottom: 4px;
    margin-right: 10px;
    font-weight: 500;
}

.ModalContent p.elements-used span.individual-element {
    padding: 0px 2px;
}

.ModalContent .Button {
    text-align: center;
    position: relative;
    right: 0;
    bottom: 0;
    width: 100%;
    margin-top: 0.2rem;
    padding: 10px 24px;
    background: rgba(249, 0, 77, 0.7);
    color: #fff;
    text-decoration: none;
    border: none;

}

.ModalContent .Button:hover {
    background: rgba(249, 0, 77, 0.9);
}

.MdClose {
    cursor: pointer;
    position: relative;
    display: block;
    top: 8px;
    right: 20px;
    width: 25px;
    height: 25px;
    padding: 0;
    margin: 0;
    z-index: 10;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.2);
    transition: transform .30s, opacity .25s;
}

.MdClose:hover {
    transform: rotate(180deg);
}