.gallery {
    position: relative;
    display: block;
    background-color: black;
    padding-bottom: 50px;
}

.gallery .gallery-bg {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;

}

.container {
    margin-top: 50px;
    text-align: center;
    margin: 0rem 5rem;
}

.section_headings {
    display: inline-block;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 0;
    background-color: rgba(0, 0, 0, .05);
    color: #333;
    padding: 10px 10px;
    margin-bottom: 15px;
    background-color: #fff;
    margin-left: 2rem;
    margin-top: 2rem;
}

.image_grid {
    position: relative;

    // display: grid;
    // grid-gap: 10px;
    // grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    // grid-auto-rows: minmax(50px, auto);

    column-width: calc(100px + 10vw);
    /* mimic a bit minmax , tune to your needs */
    // padding-left: 2.5%;
    margin: 0;
    overflow-x: hidden;

    // .image_item:nth-child(5n) {
    //     // grid-column-end: span 2;
    // }

    .image_item {
        overflow: hidden;
        position: relative;

        animation: fadeIn 1s ease-in-out;
        margin-bottom: 10px;

        &:hover .view_image {
            transform: translate(-50%, -50%);
            opacity: 1;
        }
    }


    img {
        display: flex;
        width: 100%;
        height: 100%;
        object-fit: contain;
        z-index: 1;
        box-shadow: none;
        transition: transform 0.2s ease, filter 0.2s ease;
        transform-origin: center center;
        overflow-clip-margin: content-box;
        overflow: clip;
        will-change: transform;
        vertical-align: middle;

        &:hover {
            transform: scale(1.1);
            filter: brightness(25%);
            cursor: pointer;
        }

    }

    .view_image {
        font-family: Open Sans, sans-serif;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -10%);
        opacity: 0;
        transition: transform 0.2s ease, opacity 0.2s ease;
        color: white;
        letter-spacing: 1.8px;
        text-transform: uppercase;
        font-size: 14px;
        line-height: 20px;
        padding: 10px;
        border-radius: 5px;
        pointer-events: none;
    }
}

.toggleButtonGroup {
    display: flex !important;
    justify-content: space-around !important;
    margin-top: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
}

.toggleButton {
    color: white;
}

.showButton {
    font-family: Open Sans, sans-serif;
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8); // dark transparent background
    color: white; // white text
    border: none; // remove border
    letter-spacing: 1.8px;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    cursor: pointer; // change cursor to pointer on hover
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}