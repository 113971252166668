/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500&display=swap");

html {
    font-family: "Montserrat";
} */

.container {
    min-height: 100vh;
    min-width: 100% !important;
    align-items: center;
    justify-content: center;
    padding: 100px 0;
}

section.experience-section {
    /* position: relative; */
    padding-top: 5rem;
    padding-bottom: 2rem;
    background-color: black;
    margin: 0rem 0rem !important;

}

.experience-section .section-headings {
    background-color: #fff;
    margin-left: 2rem;
}

.experience-container {
    padding-left: 6rem;
    padding-right: 6rem;
}

.experience-container div.company {
    font-size: 1.5rem;
    font-weight: 700;
    color: #fff;
    display: inline-block;
    margin: 3rem 1rem;
    position: relative;
}

/* css imported from - https://codepen.io/Web_Cifar/pen/jObBvqN */


.timeline {
    width: 80%;
    height: auto;
    max-width: 1500px;
    margin: 0 auto;
    position: relative;
}

.timeline ul {
    list-style: none;
}

.timeline ul li {
    padding: 20px;
    background-color: #1e1f22;
    color: white;
    border-radius: 10px;
    margin-bottom: 20px;
}

/* .timeline ul li:last-child {
    margin-bottom: 0;
} */

.timeline-content h1 {
    font-weight: 500;
    font-size: 1.2rem;
    /*25px;*/
    line-height: 30px;
    /* margin-bottom: 10px; */
}

.experience-container span.location {
    font-size: 0.8rem;
    margin-bottom: 0.1rem;
    color: #22A39F;
}


.timeline-content .summary {
    font-size: 1.0rem;
    /* 16px; */
    line-height: 30px;
    font-weight: 300;
}

.timeline-content .date {
    font-size: 1.0rem;
    /* 12px; */
    font-weight: 300;
    margin-bottom: 10px;
    letter-spacing: 2px;
}

@media only screen and (min-width: 768px) {
    .timeline:before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 2px;
        height: 100%;
        background-color: gray;
    }

    .timeline ul li {
        width: 50%;
        position: relative;
        margin-bottom: 50px;
    }

    .timeline ul li:nth-child(odd) {
        float: left;
        clear: right;
        transform: translateX(-45px);
        border-radius: 20px 0px 20px 20px;
    }

    .timeline ul li:nth-child(even) {
        float: right;
        clear: left;
        transform: translateX(15px);
        border-radius: 0px 20px 20px 20px;
    }

    .timeline ul li::before {
        content: "";
        position: absolute;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background-color: white;
        top: 0px;
    }

    .timeline ul li:nth-child(odd)::before {
        transform: translate(50%, -50%);
        right: -30px;
    }

    .timeline ul li:nth-child(even)::before {
        transform: translate(-50%, -50%);
        left: -30px;
    }

    .timeline-content .date {
        position: absolute;
        top: -30px;
    }

    .timeline ul li:hover::before {
        background-color: aqua;
    }
}


.each-work {
    border-top: 1px rgba(255, 255, 255, 0.192) solid;
    padding-top: 1rem;
}

.each-work:nth-child(1) {
    border-top: transparent;
}


/* .experience-container span.summary {
    padding-top: 0.2rem;
    font-size: 1rem;
    color: rgb(234, 234, 234);
} */

/* .experience-container span.summary-heading {
    font-weight: 700;
} */

.experience-container .detail-button {
    margin-top: 0.5rem;
    display: block;
    color: rgb(0, 0, 0);
    background-color: #fff;
    border: 1px solid #fff;
    font-size: 1rem;
    font-weight: 500;
    padding: 5px 18px;
}

.switch {
    width: 100%;
    margin-top: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.switch-button {
    background: rgba(255, 255, 255, 1);
    border-radius: 30px;
    overflow: hidden;
    width: 234px;
    text-align: center;
    font-size: 14px;
    letter-spacing: 1px;
    color: rgb(0, 0, 0);
    position: relative;
    padding-right: 120px;
    position: relative;
}

.switch-button::before {
    content: "Volunteer";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    pointer-events: none;
}

.switch-button-checkbox {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;
}

.switch-button-checkbox:checked+.switch-button-label::before {
    transform: translateX(120px);
    transition: transform 300ms linear;
}

.switch-button-checkbox+.switch-button-label {
    position: relative;
    padding: 15px 0;
    display: block;
    user-select: none;
    pointer-events: none;
}

.switch-button-checkbox+.switch-button-label::before {
    content: "";
    background: #F9004D;
    background: linear-gradient(0.25turn, #F9004D, #e20148, #c7003f);
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 30px;
    transform: translateX(0);
    transition: transform 300ms;
}

.switch-button-label-span {
    position: relative;
}