@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

.skill-section {
    color: white;
    padding-top: 5rem;
    padding-bottom: 2rem;
    background-color: black;
}

.skill-section .skill-heading {
    margin-left: 4rem;
    background-color: rgb(255, 255, 255);
}

.skill-container {
    padding-top: 0.5rem;
    padding-left: 6rem;
    padding-right: 8rem;
}

.skill-tagline {
    text-align: center;
    display: block;
    font-size: 1.3rem;
    font-weight: 600;
}

.skills {
    margin-top: 1rem;
    margin-left: 1.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.skill-row:hover {
    background-color: rgba(255, 255, 255, 0.219);
}

.skill-section .skillname {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    display: inline-block;
    margin-right: 1rem;
    margin-bottom: 2rem;
    padding: 0.5rem 1rem;
    border: solid 5px;
    border-image-slice: 1;
    border-image-source: linear-gradient(0.25turn, #F9004D, #e20148, #c7003f);
}


.stars {
    float: right;
    transition-timing-function: ease-in-out;
}

.filled-star {
    color: #ffc107;
}

.unfilled-star {
    color: rgb(235, 235, 235);
}

@media screen and (max-device-width: 450px) {
    .skill-container {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}