@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald&family=Poppins:wght@700&display=swap');

#contactme {
    position: relative;
    color: white;
    background-size: cover;
    width: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("../img/contactme2.jpeg");
    border-top: 2px solid white;
    background-repeat: no-repeat;
    float: left;
    background-position-y: 30%;
    z-index: 9999;
}

#contactme .shape {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100vw;
    top: -2px;
    transform: rotate(180deg);
    z-index: 2;
}

#contactme .shape .svg {
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100%;
    min-height: 2rem;
    background: linear-gradient(0deg, #ffffff 30%, rgba(0, 255, 255, 0) 0%);
}

#contactme .shape img {
    border: none;
    box-shadow: none;
    vertical-align: middle;
}

#contactme .form-container {
    padding-top: 6rem;
    margin-bottom: 6rem;
}

#contactme .form-container span.contactme-heading {
    display: block;
    font-family: 'Poppins', sans-serif;
    color: white;
    font-size: 3.5rem;
    margin-left: 4rem;
    text-transform: initial;
    padding: 0;
    margin: 0;
    align-self: center;
    justify-self: center;
    background-color: transparent;
}

#contactme .form-container .contact-left {
    justify-content: center;
    align-items: center;
}

#contact-form form .input-group .textarea {
    display: block;
}

#contact-form form input.form-control {
    margin: 2rem 0rem;
}

#contact-form form input.submit-button {
    margin: 2rem 0rem;
    width: 100%;
    background-color: rgb(0, 0, 0);
    color: #fff;
    border: 1px solid rgb(0, 0, 0);
    padding: 7px 0px;
    border-radius: 0.25rem;
    font-weight: 500;
    text-transform: uppercase;
}

#contactme-form-status {
    width: 100%;
    text-align: center;
    padding: 10px;
    border-radius: 0.25rem;
    margin-top: 0rem;
}

#contactme-form-status.success {
    background-color: rgb(211, 250, 153);
    color: rgb(0, 0, 0);
    font-weight: 500;
    animation: status 4s ease forwards;
}

#contactme-form-status.error {
    background-color: rgb(250, 129, 92);
    color: rgb(0, 0, 0);
    font-weight: 500;
    animation: status 4s ease forwards;
}

@keyframes status {
    0% {
        opacity: 1;
        pointer-events: all;
    }

    90% {
        opacity: 1;
        pointer-events: all;
    }

    100% {
        opacity: 0;
        pointer-events: none;
    }
}

#contactme .justify-content-center {
    justify-content: center;
    align-items: center;
}

#contactme .form-container .contact-left div span.contact-detail-heading {
    display: block;
    font-family: 'Oswald', sans-serif;
    color: white;
    font-size: 1.8rem;
    font-weight: 700;
    margin-left: 4rem;
    padding: 0;
    margin: 0;
    align-self: center;
    justify-self: center;
    letter-spacing: 0;
}

#contactme .form-container .contact-left div.contactme-each-section {
    margin-bottom: 15px;
}