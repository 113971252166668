section.aboutme {
    background-color: black;
    position: relative;
    display: block;
    padding-top: 5rem;
    padding-bottom: 5rem;
    box-sizing: border-box;
    width: 100vw;
}

.about-pic-col {
    text-align: center;
    padding-right: 0px;
    position: relative;
}

img {
    width: 60%;
    box-shadow: 0 0 40px rgb(0 0 0 / 30%);
}

.about-row {
    margin-left: 3rem;
    margin-right: 3rem;
}

.about-text-col {
    text-align: left;
}

span.about-text {
    margin-bottom: 15px;
    background-color: rgb(255, 255, 255);
}

h3.about-heading {
    font-size: 2rem;
    letter-spacing: 1px;
    text-shadow: 0px 1px, 1px 0px, 1px 1px;
    font-weight: 800;
    font-family: "Poppins";
    /* color: #000; */
    color: #fff;
    line-height: 2.5rem;

}

p.about-para {
    color: #d5d5d5;
    font-size: 1.1rem;
    line-height: 2rem;
    letter-spacing: .5px;
    word-wrap: break-word;
    font-weight: 600;
}

ul.about-ul {
    display: flex;
    flex-direction: flex-row;
    justify-content: flex-start;
    padding-left: 0;
    list-style-type: none;
}

ul.about-ul li {
    margin-right: 2rem;
}

ul.about-ul li:after {
    content: "";
    display: block;
    width: 50%;
    border-bottom: 0.2rem solid #fff;
    transition: 0.2s;
}

ul.about-ul li button {
    display: inline-block;
    background-color: transparent;
    border: none;
    font-size: 1.2rem;
    font-weight: 500;
    color: #fff;
    text-align: center;
    text-decoration: none;
}

ul.about-ul li.active:after {
    content: "";
    display: block;
    width: 100%;
    border-bottom: 0.2rem solid #F9004D;
    border-bottom-color: linear-gradient(0.25turn, #F9004D, #e20148, #c7003f);

}

ul.about-ul li.active button {
    color: #F9004D;
    color: linear-gradient(0.25turn, #F9004D, #e20148, #c7003f);
}

@media screen and (max-device-width: 500px) {
    ul.about-ul {
        flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;
    }

    ul.about-ul li {
        text-align: center;
        background-color: #fff;
        margin: 0.3rem 0rem;
        border-radius: 10px;
    }

    ul.about-ul li button {
        color: black;
    }

    ul.about-ul li::after {
        width: 100%;
        border-bottom: none;
    }

    ul.about-ul li.active {
        background-image: linear-gradient(0.25turn, #F9004D, #e20148, #c7003f);
    }

    ul.about-ul li.active button {
        color: #fff;
    }

    ul.about-ul li.active:after {
        border-bottom: none;

    }
}

@media (max-width: 850px) {
    .about-pic-col {
        display: none;
    }
}