.Header {
  height: 100vh;
  position: relative;
  display: block;
  background-size: contain;
  background-color: black;
  background-image: linear-gradient(0.90turn, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../img/me.jpg");

  background-repeat: no-repeat;
  background-position-y: 50%;
  background-position-x: right;
}

/* @keyframes makeTextAppear {
  0% {
    opacity: 0%;
  }

  25% {
    opacity: 25%;
  }

  50% {
    opacity: 50%;
  }

  75% {
    opacity: 75%;
  }

  100% {
    opacity: 100%;
  }
} */

.particles-js canvas {
  height: 100vh !important;
  transform: scale(1);
  opacity: 1;
  transition: opacity .8s ease, transform 1.4s ease;
}

#text_div {
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: 10px;
}

.text-location {
  position: absolute;
  top: 35%;
  left: 5%;
  text-align: left;
  color: white;
}

.AboutText {
  display: inline-block;
  font-size: 3.5rem;
  font-weight: 700;
}

@keyframes textAnimation {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  10% {
    opacity: 0;
    transform: translateY(30px);
  }

  25% {
    opacity: 1;
    transform: translateY(0);
  }

  75% {
    opacity: 1;
    transform: translateY(0);
  }

  90% {
    opacity: 0;
    transform: translateY(-30px);
  }

  100% {
    opacity: 0;
    transform: translateY(-30px);
  }
}


.AboutText span.Animated-Text {
  color: #F9004D;
  color: linear-gradient(0.25turn, #F9004D, #e20148, #c7003f);
  display: inline-block;
  opacity: 0;
  font-size: 5.5rem;
  font-weight: 800;
  animation-name: textAnimation;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  white-space: normal;

}

@media (max-width: 1024px) {


  .Header {
    height: 100vh;
    display: block;
    padding-bottom: 4rem;
    display: flex;
    background-size: cover;
    background-position-x: center;
  }

  .text-location {
    padding-top: 5rem;
    position: relative;

  }

  .AboutText span.Animated-Text {
    font-size: 4.5rem;
  }
}

@media screen and (max-device-height: 450px) and (max-device-width: 900px) {
  .text-location {
    top: 5%;
  }

}

@media screen and (max-device-width: 480px) {

  .AboutText {
    font-size: 1.7rem;
  }

  .AboutText span.Animated-Text {
    font-size: 2rem;
  }
}

.Header {
  overflow: hidden;
}