@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');

.navbar {
    position: fixed;
    float: right;
    z-index: 99999;
    height: 100vh;
    right: 0;
    margin-right: 1.5rem;
}

.navbar .navbar-nav {
    text-align: center;
    list-style-type: circle;
    font-size: 1rem;
    color: #fff;
}

.navbar .navbar-nav li a {
    margin: 0rem;
    text-decoration: none;
    color: rgb(0, 0, 0);
    padding: 0.1rem 0.5rem;
    display: None;
    font-weight: 500;
}

.navbar .navbar-nav li {
    margin: 0.2rem 0rem;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 10px;
}

.navbar-nav:hover {
    li a {
        display: inline-block;
    }
}

.navbar .navbar-nav li:hover,
.navbar .navbar-nav li:focus {
    list-style-type: disc;
    background-image: linear-gradient(0.25turn, #F9004D, #e20148, #c7003f);
    border-image-source: linear-gradient(0.25turn, #F9004D, #e20148, #c7003f);
    color: #F9004D;

    a {
        color: #fff;
    }
}