html,
body {
  height: 100%;
}

span.section-headings {
  display: inline-block;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0;
  background-color: rgba(0, 0, 0, .05);
  color: #333;
  padding: 10px 10px;
  margin-bottom: 15px;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}